import React from "react"
import "./banner.styles.scss"
import { graphql, useStaticQuery } from "gatsby"
import { convertToBgImage } from "gbimage-bridge"
import { getImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import Header from "../../../components/header/header.component"

const BannerService = props => {
  const { backgroundImage } = useStaticQuery(
    graphql`
      query {
        backgroundImage: file(relativePath: { eq: "bg-2.png" }) {
          id
          childImageSharp {
            gatsbyImageData(
              width: 2000
              quality: 100
              webpOptions: { quality: 100 }
            )
          }
        }
      }
    `
  )

  const image = getImage(backgroundImage)
  const bgImg = convertToBgImage(image)
  return (
    <>
      <BackgroundImage Tage="section" {...bgImg} preserveStackingContext>
        <div className="container">
          <Header url="/services" />
          <div className="banner sub-container">
            <div className="h3">
              Services - <br /> {props.title}{" "}
            </div>
          </div>
        </div>
      </BackgroundImage>
    </>
  )
}

export default BannerService
