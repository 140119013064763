import React from "react"
import { graphql } from "gatsby"
import FAQ from "../../components/faqSection.component"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BannerService from "./banner/services.component"

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { path: { eq: $slug } }) {
      html
      frontmatter {
        heroTitle
        title
        meta {
          title
          description
        }
        image {
          id
          childImageSharp {
            fluid {
              originalImg
            }
          }
        }
        description
        author
      }
    }
  }
`

const ServiceDetails = props => {
  const { heroTitle, title, image, description, meta } =
    props.data.markdownRemark.frontmatter
  return (
    <>
      <Seo {...meta} />
      <BannerService title={heroTitle} />
      <Layout>
        <div className="section sub-container">
          <div className="flex space-between">
            <div>
              <h1 className="h3">{title}</h1>
              <p style={{ maxWidth: "650px" }}>{description} </p>
            </div>
            <div className="image-service">
              <img
                src={`${image.childImageSharp.fluid.originalImg}`}
                alt="service"
              />{" "}
            </div>
          </div>
          <div className="section">
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{
                __html: props.data.markdownRemark.html,
              }}
            ></div>
          </div>
          <FAQ />
          <div className="mt-4">
            <h6>Contact Us ASAP for Dental Emergencies</h6>{" "}
            <p>
              {" "}
              Don’t wait for the problem to worsen before you decide to ask
              assistance from the emergency dentist in La Mesa. The sooner that
              we can handle the issue, the better. This will also help you
              experience pain relief as soon as possible.
            </p>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default ServiceDetails
